import PNTrading from 'src/pn-trading-sdk';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Table from '../../components/Table';
import Icon from '../../elements/Icon';
import Card from '../../layout/Card';
import { formatPrice, getLocalDate } from '../../utils/Util';
import DeletePayment from '../invoices/components/DeletePayment';
import usePopup from '../../utils/hooks/usePopup';
import Button from '../../elements/Button';
import { useAuctions } from 'src/components/auction/AuctionSelect';
import Invoices from 'src/utils/api/Invoices';

const limit = 10;

export default function EndedObjects({ user }) {
	const history = useHistory();

	const [payments, setPayments] = useState();
	const fetching = useRef(false)

	const [open] = usePopup()

	const auctions = useAuctions();

	const load = async ({ sorting, filters, page } = {}) => {
		if (fetching.current) return;
		fetching.current = true;
		let payments = await PNTrading.get(`/v2/invoices?user=${user.id}&limit=${limit}&page=${page ?? 0}&embed=true`);
		setPayments(payments);
		fetching.current = false;
		return payments.data;
	};

	async function onClickCreateInvoice() {
		const resp = await Invoices.create({
			user_id: user.id
		});

		history.push(`/invoices/${resp.id}`);
	}

	useEffect(() => {
		load();
	}, [user.id]);

	return (
		<Style size="large">
			
			<div className="flex row">
				<div className="spacer auto"></div>
				<Button onClick={onClickCreateInvoice}>Skapa faktura</Button>
			</div>

			{(!auctions || !payments) ? (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100%',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
					<div className="loader"></div>
				</div>
			) : (
				<Table
					pages={payments ? payments.pages : 1}
					limit={limit}
					data={payments ? payments.data : []}
					load={load}
					structure={[
						{
							heading: 'ID',
							key: 'id',
						},
						{
							heading: "Auktion",
							component: (row) => {
								const auctionIDs = [...new Set(row.items.map(i => i.auction_id))].filter(a => a);

								if (auctionIDs.length > 1)
									return <div>Blandauktion</div>
								else if (auctionIDs.length === 1)
									return <div>{auctions?.find(a => a.id === auctionIDs[0])?.name}</div>
								else
									return '-'
							}
						},
						{
							heading: 'Betalningsmetod',
							key: 'type',
							component: (row) => {
								return {
									null: '-',
									onsite_card: 'På plats - Kort',
									onsite_cash: 'På plats - Kontant',
									onsite_swish: 'På plats - Swish',
									invoice_bank: 'Faktura - Inbetalning Bank',
									invoice_swish: 'Faktura - Swish',
								}[row.payments[0]?.payment_method ?? null];
							},
						},
						{
							heading: 'Status',
							key: 'status',
							component: (row) => {
								return {
									processing: <div className="badge red">OBETALD</div>,
									canceled: <div className="badge gray">AVBRUTEN</div>,
									paid: <div className="badge green">BETALD</div>,
								}[row.status ?? 'processing'];
							},
						},
						{
							heading: 'Skapad',
							key: 'time_created',
							component: (row) => {
								return getLocalDate(row.created_at).getTimestamp();
							},
						},
						{
							heading: 'Betalad',
							key: 'payed_date',
							component: (row) => {
								return row.paid_date ? getLocalDate(row.paid_date).getTimestamp() : '-';
							},
						},
						{
							heading: 'Totalt',
							key: 'total',
							component: (row) => {
								return row.total_net_amount ? formatPrice(Math.ceil(row.total_net_amount + row.total_vat_amount)) : '-';
							},
						},
						{
							heading: '',
							className: 'row-icons',
							component: (row) => {
								return (
									<div>
										<Icon onClick={() => history.push(`/invoices/${row.id}`)}>edit</Icon>
										{(row.status !== 'paid') && <Icon onClick={() => open(<DeletePayment payment={row} onDeleted={() => load()} />)}>delete</Icon>}
									</div>
								);
							},
						},
					]}
				/>
			)}
		</Style>
	);
}

const Style = styled(Card)`
	.tag {
		padding: 0.5rem 1rem;
		background: black;
		border-radius: 0.35rem;
		color: white;

		&.green {
			background: #21ab21;
		}

		&.red {
			background: #f5614f;
		}

		&.gray {
			background: #d9d9d9;
			color: #777;
		}
	}

	.id {
		width: 7rem;
	}

	.title {
		width: 25rem;
	}

	.date {
		width: 10rem;
	}

	.status {
		width: 15rem;
	}

	.row-icons {
		div {
			display: flex;
			justify-content: flex-end;
			font-size: 1.5em;

			.icon {
				margin-left: 0.5em;
			}
		}
	}
`;
