import PNTrading from 'src/pn-trading-sdk';
import React from 'react';
import Icon from '../../elements/Icon';
import { UseInvoiceInvoice } from 'src/utils/api/Invoices';

export default function ShowPaymentQRButton({ invoice }: { invoice: UseInvoiceInvoice }) {

	if (!invoice) return null;

	return (
		<div
			className="icon-button"
			onClick={() => {
				if (!invoice.isValid()) {
					alert('Fakturan saknar information');
					return;
				}

				const total = invoice.total_net_amount + invoice.total_vat_amount;
				let message = `Betalning nr. ${invoice.id}`;
				if (invoice.buyer_first_name) message += `, ${invoice.buyer_first_name}`;
				if (invoice.buyer_last_name) message += ` ${invoice.buyer_last_name}`;

				window.open(`${PNTrading.getURL()}/swish-qr?total=${Math.ceil(total)}&message=${encodeURIComponent(message)}`, '_blank')?.focus();
			}}
		>
			<Icon>qr_code</Icon>
			<span>Visa swish kod</span>
		</div>
	);
}
