import { useEffect, useState } from 'react';
import API from './API';
import { Override } from 'src/utils/Types';

export const InvoiceStatus = {
	Unpaid: 'unpaid',
	Paid: 'paid',
	Draft: 'draft',
} as const;

export const PaymentMethod = {
	OnsiteCard: 'onsite_card',
	OnsiteCash: 'onsite_cash',
	OnsiteSwish: 'onsite_swish',
	InvoiceBank: 'invoice_bank',
	InvoiceSwish: 'invoice_swish',
} as const;

export type Invoice = {
	id: number;
	created_at: Date;
	buyer_id: number;
	buyer_org_nr: string;
	buyer_first_name: string;
	buyer_last_name: string;
	buyer_address: string;
	buyer_zip_code: string;
	buyer_city: string;
	buyer_country: string;
	buyer_email: string;
	buyer_phone: string;
	buyer_company_name: string;
	total_net_amount: number;
	total_vat_amount: number;
	status: typeof InvoiceStatus[keyof typeof InvoiceStatus];
	due_date: Date;
	paid_date: Date;
	items: null;
	payments: null;
}

export type InvoiceItem = {
	id: number;
	invoice_id: number;
	readable_id: string;
	created_at: string;
	name: string;
	object_id: number | null;
	seller_id: number | null;
	auction_id: number | null;
	net_amount: number;
	vat_amount: number;

	/**
	 * String representing a date.
	 */
	delivered: string | null;
	
	/**
	 * String representing a date.
	 */
	accounted: string | null;
	owned: boolean;
}

export type InvoicePayment = {
	id: number;
	invoice_id: number;
	created_at: string;
	payment_date: string;
	amount: number;
	payment_method: typeof PaymentMethod[keyof typeof PaymentMethod];
	payment_reference: string | null;
}

export type EmbeddedInvoice = Override<Invoice, {
	items: null | InvoiceItem[];
	payments: null | InvoicePayment[];
}>;

export type CreateInvoiceArgs = {
	user_id?: number;
}

export type UseInvoiceInvoice = EmbeddedInvoice & {
	/**
	 * Checks if the invoice has all necessary fields filled out to be valid.
	 */
	isValid: () => boolean;

	/**
	 * Updates the invoice with the given data.
	 */
	update: (data: Omit<Partial<Invoice>, 'items' | 'payments' | 'created_at' | 'id'> & { user_id?: number }) => Promise<Invoice>;

	/**
	 * Adds an item to the invoice.
	 */
	addItem: (data: Partial<Omit<InvoiceItem, 'id' | 'invoice_id'>>) => Promise<EmbeddedInvoice>;

	/**
	 * Adds an object to the invoice.
	 */
	addObject: (object_id: number) => Promise<EmbeddedInvoice>;

	/**
	 * Removes an item from the invoice.
	 */
	removeItem: (item_id: number) => Promise<EmbeddedInvoice>;

	/**
	 * Updates an item in the invoice.
	 */
	updateItem: (item_id: number, data: Partial<Omit<InvoiceItem, 'id' | 'invoice_id'>>) => Promise<EmbeddedInvoice>;

	/**
	 * Makes a payment on the invoice.
	 */
	makePayment: (amount: number, method: typeof PaymentMethod[keyof typeof PaymentMethod]) => Promise<EmbeddedInvoice>;

	/**
	 * Marks the invoice as delivered.
	 */
	markAsDelivered: () => Promise<EmbeddedInvoice>;

	/**
	 * Marks an individual invoice item as delivered.
	 */
	markItemAsDelivered: (item_id: number) => Promise<InvoiceItem>;

	/**
	 * Marks an individual invoice item as un-delivered.
	 */
	markItemAsUndelivered: (item_id: number) => Promise<InvoiceItem>;

	/**
	 * The sum of the invoice.
	 */
	sum: number;
};

export type SellableObject = {
	id: number;
	title: string;
	auction: null | number;
	auction_name: null | string;
	thumbnail: null | string;
	leading_bid: null | number;
	num_bids_placed: number;
	invoice_id: null | number;
}

export default class Invoices {
	static async list(params = {} as any) {
		return await API.get(
			`/v2/invoices?a=b&${Object.keys(params)
				.filter((k) => params[k] != null && params[k] != '')
				.map((key) => `${key}=${params[key]}`)
				.join('&')}`
		);
	}

	static async get(id: number): Promise<EmbeddedInvoice | null> {
		const resp = await API.get(`/v2/invoices/${id}?embed=true`);
		if (!resp.success as any) return null;
		return resp.data as EmbeddedInvoice;
	}

	static async update(id: number, data: Omit<Partial<Invoice>, 'items' | 'payments' | 'created_at' | 'id'>): Promise<Invoice> {
		const resp = await API.put(`/v2/invoices/${id}`, data);
		if (!resp.success as any) throw new Error(resp.message);
		return resp.data as Invoice;
	}

	static async addItem(invoice_id: number, data: Partial<Omit<InvoiceItem, 'id' | 'invoice_id'>>): Promise<EmbeddedInvoice> {
		const resp = await API.post(`/v2/invoices/${invoice_id}/items?embed=true`, data);
		if (!resp.success as any) throw new Error(resp.message);
		return resp.invoice as EmbeddedInvoice;
	}

	static async addObject(invoice_id: number, object_id: number): Promise<EmbeddedInvoice> {
		const resp = await API.post(`/v2/invoices/${invoice_id}/items/${object_id}?embed=true`);
		if (!resp.success as any) throw new Error(resp.message);
		return resp.invoice as EmbeddedInvoice;
	}


	static async removeItem(invoice_id: number, item_id: number): Promise<EmbeddedInvoice> {
		const resp = await API.delete(`/v2/invoices/${invoice_id}/items/${item_id}?embed=true`);
		if (!resp.success as any) throw new Error(resp.message);
		return resp.invoice as EmbeddedInvoice;
	}

	static async updateItem(invoice_id: number, item_id: number, data: Partial<Omit<InvoiceItem, 'id' | 'invoice_id'>>): Promise<EmbeddedInvoice> {
		const resp = await API.put(`/v2/invoices/${invoice_id}/items/${item_id}?embed=true`, data);
		if (!resp.success as any) throw new Error(resp.message);
		return resp.invoice as EmbeddedInvoice;
	}

	static async markAsDelivered(id: number) {
		const resp = await API.post(`/v2/invoices/${id}/mark-as-delivered`);
		if (!resp.success as any) throw new Error(resp.message);
		return resp.invoice as EmbeddedInvoice;
	}

	static async markItemAsDelivered(item_id: number) {
		const resp = await API.post(`/v2/invoices/items/${item_id}/mark-as-delivered`);
		if (!resp.success as any) throw new Error(resp.message);
		return resp.item as InvoiceItem;
	}

	static async markItemAsUndelivered(item_id: number) {
		const resp = await API.post(`/v2/invoices/items/${item_id}/mark-as-undelivered`);
		if (!resp.success as any) throw new Error(resp.message);
		return resp.item as InvoiceItem;
	}

	static async create(args?: CreateInvoiceArgs) {
		return (await API.post(`/v2/invoices`, args)).data as Invoice;
	}

	static async delete(id: number) {
		return await API.delete(`/v2/invoices/${id}`);
	}

	static async makePayment(id: number, amount: number, method: typeof PaymentMethod[keyof typeof PaymentMethod]): Promise<EmbeddedInvoice> {
		const resp = await API.post(`/v2/invoices/${id}/payments`, { amount, method });
		if (!resp.success as any) throw new Error(resp.message);
		return resp.invoice as EmbeddedInvoice;
	}

	static async getSellableObjects(args: {
		search?: string;
	}): Promise<SellableObject[]> {

		const url = new URL(`/v2/invoices/objects`, API.getURL());
		if (args?.search) url.searchParams.append('search', args.search);

		const resp = await API.get(url.pathname + url.search);
		if (!resp.success as any) throw new Error(resp.message);

		return resp.objects as SellableObject[];
	}

}

export function useInvoice(id: number): { invoice: UseInvoiceInvoice | null } {
	const [invoice, setInvoice] = useState<EmbeddedInvoice | null>(null);

	useEffect(() => {
		Invoices.get(id).then(i => setInvoice(i));
	}, [id]);


	if (!invoice) return {
		invoice: null,
	};


	return {
		invoice: {
			...(invoice ?? {}),
			isValid: () => {
				return (
					!!invoice.buyer_first_name &&
					!!invoice.buyer_last_name &&
					!!invoice.buyer_address &&
					!!invoice.buyer_zip_code &&
					!!invoice.buyer_city
				);
			},
			update: async (data) => {
				const updated = await Invoices.update(invoice.id, data);
				setInvoice({...invoice, ...updated});
				return updated;
			},
			addItem: async (data) => {
				const updated = await Invoices.addItem(invoice.id, data);
				setInvoice(updated);
				return updated;
			},
			addObject: async (object_id) => {
				const updated = await Invoices.addObject(invoice.id, object_id);
				setInvoice(updated);
				return updated;
			},
			removeItem: async (item_id) => {
				const updated = await Invoices.removeItem(invoice.id, item_id);
				setInvoice(updated);
				return updated;
			},
			updateItem: async (item_id, data) => {
				const updated = await Invoices.updateItem(invoice.id, item_id, data);
				setInvoice(updated);
				return updated;
			},
			makePayment: async (amount, method) => {
				const updated = await Invoices.makePayment(invoice.id, amount, method);
				setInvoice(updated);
				return updated;
			},
			markAsDelivered: async () => {
				const updated = await Invoices.markAsDelivered(invoice.id);
				setInvoice(updated);
				return updated;
			},
			markItemAsDelivered: async (item_id) => {
				const updated = await Invoices.markItemAsDelivered(item_id);
				const updatedInvoice = {...invoice};
				const itemIndex = updatedInvoice.items!.findIndex((i) => i.id === item_id);
				if (itemIndex === -1) return updated;
				updatedInvoice.items![itemIndex] = updated;
				setInvoice(updatedInvoice);
				return updated;
			},
			markItemAsUndelivered: async (item_id) => {
				const updated = await Invoices.markItemAsUndelivered(item_id);
				const updatedInvoice = {...invoice};
				const itemIndex = updatedInvoice.items!.findIndex((i) => i.id === item_id);
				if (itemIndex === -1) return updated;
				updatedInvoice.items![itemIndex] = updated;
				setInvoice(updatedInvoice);
				return updated;
			},
			sum: invoice ? invoice.total_net_amount + invoice.total_vat_amount : 0,
		},
	};
}
