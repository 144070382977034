import React, { useState, useEffect } from 'react';
import Select, { Option } from '../../elements/Select';
import Auctions from 'src/utils/api/Auctions';

/**
 * 
 * @param {*} param0 
 * @returns 
 */
export default function AuctionSelect({ defaultValueID, ...rest }) {
	let defaultValue = rest.defaultValue;

	if (defaultValue == -1) {
		defaultValue = undefined;
	}

	const auctions = useAuctions();

	if (defaultValueID && auctions) {
		defaultValue = auctions.findIndex((c) => c.id == defaultValueID);
	}

	return (
		<Select {...rest} defaultValue={defaultValue}>
			{auctions?.map((auction) => (
				<Option key={auction.id} value={auction.id}>{`${auction.id} - ${auction.name}`}</Option>
			))}
		</Select>
	);
}

export function useAuctions() {
	const [auctions, setAuctions] = useState();

	useEffect(() => {
		Auctions.list().then(setAuctions);
	}, []);

	return auctions;
}
