import React, { useState } from 'react';
import Icon from '../../elements/Icon';
import styled from 'styled-components';
import SellerSelect from '../sellers/SellerSelect';
import { useSellers } from '../../utils/api/Sellers';
import { InvoiceItem, UseInvoiceInvoice } from 'src/utils/api/Invoices';
import DeleteInvoiceItemButton from 'src/components/invoices/DeleteInvoiceItemButton';
import Checkbox from 'src/elements/Checkbox';
import usePopup from 'src/utils/hooks/usePopup';
import AddObjectPopup from 'src/components/invoices/AddObjectPopup';

export default function InvoiceTable({ invoice }: { invoice: UseInvoiceInvoice }) {
	const { sellers } = useSellers() as any;

	const [editing, setEditing] = useState<null | number>(null);
	const [editingSeller, setEditingSeller] = useState<null | number>(null);
	const [open] = usePopup();

	const [seller, setSeller] = useState()

	async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		const formData = new FormData(e.currentTarget);
		const data = {...Object.fromEntries(formData.entries()), seller_id: seller};

		try {
			await invoice.addItem(data);

			// @ts-ignore
			e.target?.reset();
			setSeller(undefined);
		} catch (e) {
			console.error(e);
		}
	}

	async function onSubmitEdit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		const formData = new FormData(e.currentTarget);
		const data: any = {...Object.fromEntries(formData.entries()), seller_id: editingSeller};
		if (data.seller_id === undefined) data.seller_id = null;
		else if (data.seller_id === null) delete data.seller_id;

		data.owned = formData.get('owned') === 'on';
		
		try {
			const currentItem = invoice.items?.find((i: any) => i.id === editing);
			if (currentItem) {

				if (formData.get('delivered') === 'on' && currentItem.delivered === null) {
					await invoice.markItemAsDelivered(currentItem.id);
				} else if (formData.get('delivered') !== 'on' && currentItem.delivered !== null) {
					await invoice.markItemAsUndelivered(currentItem.id);
				}

				delete data.delivered;
			}
		} catch (e) {
			console.error(e);
		}

		if (!editing) return console.error('No editing item');

		if (data.net_amount === '') data.net_amount = 0;
		if (data.vat_amount === '') data.vat_amount = 0;

		try {

			await invoice.updateItem(editing, data);

			setEditingSeller(null);
			setEditing(null);
		} catch (e) {
			console.error(e);
		}
	}

	function toggleEdit(itemID: number) {
		return () => {

			// @ts-ignore
			document.querySelector('#invoice_table_edit_form')?.reset();

			if (editing === itemID) {
				setEditingSeller(null);
				setEditing(null);
			} else {
				setEditing(itemID);
			}
		}
	}

	return (
		<Style>

			<form id="invoice_table_create_form" onSubmit={onSubmit}></form>
			<form id="invoice_table_edit_form" onSubmit={onSubmitEdit}></form>

			<div className="row center">
				<div className="h3">{`Faktura nr. ${invoice.id}`}</div>
			</div>
			<div className="spacer s"></div>
			<div className="table-wrapper">
				<table className="meta-table" cellSpacing={10}>
					<thead>
						<tr>
							<th className='col-id'>ID</th>
							<th className='col-seller'>Säljare</th>
							<th className='col-name'>Artikel</th>
							<th className='col-amount'>Pris</th>
							<th className='col-amount'>Moms</th>
							<th className='col-owned'>Kostnad</th>
							<th className='col-delivered'>Hämtad</th>
							<th></th>
						</tr>
					</thead>
					<tbody>

						{invoice.items?.map((item: InvoiceItem, index: number) => {

							const edit = editing === item.id;

							return (
								<tr key={item.id} className={false ? 'removed' : ''}>
									<td className='col-id'>
										<input
											form="invoice_table_edit_form"
											name="readable_id" 
											type="text" 
											defaultValue={item.readable_id} 
											disabled={!edit}
										/>
									</td>
									<td className='col-seller'>
										<SellerSelect
											defaultValue={(() => {
												let index = sellers?.findIndex((c: any) => c.id === item.seller_id);
												return index === -1 ? undefined : index;
											})()}
											disabled={!edit}
											ellipsis
											onSelect={(v: any) => setEditingSeller(v.value)}
										/>
									</td>
									<td className='col-name'>
										<input
											form="invoice_table_edit_form"
											name="title"
											type="text"
											defaultValue={item.name}
											disabled={!edit}
										/>
									</td>
									<td className='col-amount'>
										<div className="field-wrapper">
											<input
												form="invoice_table_edit_form"
												name="net_amount"
												type="number"
												defaultValue={item.net_amount}
												disabled={!edit}
											/> kr
										</div>
									</td>
									<td className='col-amount'>
										<div className="field-wrapper">
											<input
												form="invoice_table_edit_form"
												name="vat_amount"
												type="number"
												defaultValue={item.vat_amount}
												disabled={!edit}
											/> kr
										</div>
									</td>
									<td className='col-owned'>
										<div className="field-wrapper">
											<Checkbox
												form="invoice_table_edit_form"
												name="owned"
												defaultChecked={item.owned}
												disabled={!edit}
											/>
										</div>
									</td>
									<td className='col-delivered'>
										<div className="field-wrapper">
											<Checkbox
												form="invoice_table_edit_form"
												name="delivered"
												defaultChecked={!!item.delivered}
												disabled={!edit}
											/>

											{item.delivered && (
												<div className="delivered-info-wrapper" title={`Objektet hämtades ${new Date(item.delivered).toLocaleTimeString('sv-SE', { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' })}`}>
													i
												</div>
											)}
										</div>
									</td>
									<td>
										<div className="invoice-item-actions">


											{edit && (
												<button form="invoice_table_edit_form" type="submit">Spara</button>
											)}

											<button className="secondary black" onClick={toggleEdit(item.id)}>
												<Icon>
													{edit ? 'cancel' : 'edit'}
												</Icon>
											</button>

											<DeleteInvoiceItemButton item={item} invoice={invoice} />
										</div>
									</td>
								</tr>
							);
						})}

						<tr>
							<td colSpan={7}>
								<div style={{ borderBottom: "solid 1px gray", height: '2rem', marginBottom: '1rem' }}></div>
							</td>
						</tr>

						<tr>
							<td className='col-id'>
								<input form="invoice_table_create_form" name="readable_id" type="text" />
							</td>
							<td className='col-seller'>
								<SellerSelect
									ellipsis
									onSelect={(v: any) => setSeller(v.value)}
								/>
							</td>
							<td className='col-name'>
								<input required form="invoice_table_create_form" name="name" type="text" />
							</td>
							<td className='col-amount'>
								<div className="field-wrapper">
									<input required form="invoice_table_create_form" name="amount" type="number" /> kr
								</div>
							</td>
							<td></td>
							<td>
								<div className="field-wrapper" style={{
									display: 'flex',
									justifyContent: 'center',
									paddingLeft: 4
								}}>
									<Checkbox form="invoice_table_create_form" name="owned" />
								</div>
							</td>
							<td>
								<button form="invoice_table_create_form" type="submit">Lägg till</button>
							</td>
						</tr>

						<tr>
							<td colSpan={6}></td>
							<td>
								<button 
									className="outlined"
									onClick={() => {
										open(<AddObjectPopup invoice={invoice} />);
									}}
								>Lägg till objekt</button>
							</td>
						</tr>

						<tr>
							<td>
								<div style={{ height: '1rem' }}></div>
							</td>
						</tr>

						<tr>
							<td></td>
							<td></td>
							<td>Summa:</td>
							<td className='col-amount'>
								<div className="field-wrapper">
									<input type="number" disabled value={invoice.total_net_amount} /> kr
								</div>
							</td>
							<td className='col-amount'>
								<div className="field-wrapper">
									<input type="number" disabled value={invoice.total_vat_amount} /> kr
								</div>
							</td>
						</tr>

						<tr>
							<td></td>
							<td></td>
							<td>Att betala:</td>
							<td></td>
							<td className='col-amount'>
								<div className="field-wrapper">
									<input type="number" disabled value={Math.ceil(invoice.sum)} /> kr
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</Style>
	);
}

const Style = styled.div`

	.table-wrapper {
		overflow-x: auto;
		overflow-y: hidden;
		padding-bottom: 200px;

		.meta-table {
			min-width: 100%;

			button {
				padding-top: 0.5rem;
				padding-bottom: 0.5rem;
			}

			tbody {
				tr {
					td {
						& > * {
							width: 100%;
						}
					}

					&.removed {
						td:not(.always-visible) {
							opacity: 0.3;

							&,
							* {
								pointer-events: none;
							}
						}
					}
				}
			}

			.col-id {
				width: 150px;


				.mobile & {
					input {
						width: 125px;
					}
				}
			}

			.col-seller {
				width: 200px;

				.current > h4 {
					width: 150px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.col-name {
				width: 400px;
			}

			.col-amount {
				width: 120px;

				& > .field-wrapper {
					width: 120px;
					display: flex;
					gap: 10px;
					align-items: center;
					justify-content: space-between;

					input {
						width: 95px;
					}
				}
			}

			th.col-owned {
				padding-right: 0;
			}

			.col-owned {
				text-align: center;

				& > .field-wrapper {
					display: flex;
					justify-content: center;
				}
			}

			.col-delivered {
				.field-wrapper {
					display: flex;
					align-items: center;

					.delivered-info-wrapper {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 1rem;
						height: 1rem;
						background-color: #949494;
						border-radius: 50%;
						color: white;
						font-size: 12px;
						cursor: help;
						margin-left: 0.5rem;
					}
				}
			}

			.invoice-item-actions {
				display: flex;
				justify-content: flex-end;
				gap: 0px;
			}
		}
	}
`;
