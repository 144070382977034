import React, { useEffect, useRef, useState } from "react";
import Icon from "src/elements/Icon";
import Card from "src/layout/Card";
import PNTrading from "src/pn-trading-sdk/index";
import Users, { User } from "src/utils/api/Users";
import styled from "styled-components";

export default function UserDetailsCard(props: Props) {
    const uid = props.userID;

	const [user, setUser] = useState<User | null>(null);
	const [originalUser, setOriginalUser] = useState<User | null>(null);
	const [edit, setEdit] = useState<any>({});
    const prevEdit = useRef(edit);

    useEffect(() => {
        if (uid) Users.get(uid).then(u => {
            setUser(u);
            setOriginalUser(u);
        });
    }, [uid]);

    useEffect(() => {
		(async () => {
            for (let [key, value] of Object.entries(edit)) {
                if (!value && !!prevEdit.current[key]) {

                    try {
                        await PNTrading.put(`/users/${uid}`, { [key]: (user as any)[key] });
                    } catch(e: any) {

                        if (e?.response?.code === 'ER_DUP_ENTRY') {
                            if (user && originalUser) setUser({...user, phone: originalUser.phone});
                            alert('Det angivna telefonnummret är upptaget!')
                            continue;
                        }

                        console.error(e);
                    }
                }
            }

            prevEdit.current = edit;

            setOriginalUser(user);
		})()
	}, [edit, uid, user, originalUser]);

    if (!user) return null;

    return (
        <Style size="medium" className="user-info">
            <div className="title-row">
                <input id="title-field" type="text" className="title" value={user.username} disabled={!edit.username} onChange={(e) => setUser((u) => (u ? { ...u, username: e.target.value } : null))} />
                <Icon
                    onClick={() => {
                        if (!edit.username) {
                            setTimeout(() => {
                                let element = document.getElementById('title-field');
                                if (element) {
                                    element.focus();
                                }
                            }, 0);
                        }

                        setEdit((e: any) => ({ ...e, username: !e.username }));
                    }}
                >
                    {edit.username ? 'save' : 'edit'}
                </Icon>
            </div>

            <div className="spacer xs"></div>

            <div className="name-row">
                <input
                    id="name-field"
                    type="text"
                    className="name"
                    defaultValue={`${user.first_name} ${user.last_name}`}
                    disabled={!edit.name}
                    onChange={(e) =>
                        setUser((u) => (u ? {
                            ...u,
                            first_name: e.target.value.split(' ')[0],
                            last_name: e.target.value.replace(e.target.value.split(' ')[0], '').trim(),
                        } : null))
                    }
                />

                <Icon
                    onClick={() => {
                        if (!edit.name) {
                            setTimeout(() => {
                                let element = document.getElementById('name-field');
                                if (element) {
                                    element.focus();
                                    // element.setSelectionRange(element.value.length, element.value.length);
                                }
                            }, 0);
                        }

                        setEdit((e: any) => ({ ...e, name: !e.name }));
                    }}
                >
                    {edit.name ? 'save' : 'edit'}
                </Icon>
            </div>

            <div className="spacer xs"></div>

            <div className="name-row">
                <input
                    id="org-field"
                    type="text"
                    className="name"
                    defaultValue={user.org_nr}
                    disabled={!edit.org_nr}
                    onChange={(e) =>
                        setUser((u) => (u ? {
                            ...u,
                            org_nr: e.target.value,
                        } : null))
                    }
                    placeholder="Personnummer"
                />

                <Icon
                    onClick={() => {
                        if (!edit.org_nr) {
                            setTimeout(() => {
                                let element = document.getElementById('org-field');
                                if (element) {
                                    element.focus();
                                }
                            }, 0);
                        }

                        setEdit((e: any) => ({ ...e, org_nr: !e.org_nr }));
                    }}
                >
                    {edit.org_nr ? 'save' : 'edit'}
                </Icon>
            </div>

            <div className="rows">
                <div className="row">Medlem sedan: {new Date(user.created).toISOString().substring(0, 10)}</div>



                <div className="name-row">
                    Epost:

                    <div className="spacer wxs"></div>

                    <input
                        id="email-field"
                        type="email"
                        className="name"
                        defaultValue={user.email}
                        disabled={!edit.email}
                        onChange={(e) =>
                            setUser((u) => (u ? {
                                ...u,
                                email: e.target.value,
                            } : null))
                        }
                        placeholder="Email"
                    />

                    <Icon
                        onClick={() => {
                            if (!edit.email) {
                                setTimeout(() => {
                                    let element = document.getElementById('email-field');
                                    if (element) element.focus();
                                }, 0);
                            }

                            setEdit((e: any) => ({ ...e, email: !e.email }));
                        }}
                    >
                        {edit.email ? 'save' : 'edit'}
                    </Icon>
                </div>


                <div className="name-row">
                    Telefonnummer:

                    <div className="spacer wxs"></div>

                    <input
                        id="phone-field"
                        type="phone"
                        className="name"
                        value={user.phone}
                        disabled={!edit.phone}
                        onChange={(e) =>
                            setUser((u) => (u ? {
                                ...u,
                                phone: e.target.value,
                            } : null))
                        }
                        placeholder="Telefonnummer"
                    />

                    <Icon
                        onClick={() => {
                            if (!edit.phone) {
                                setTimeout(() => {
                                    let element = document.getElementById('phone-field');
                                    if (element) element.focus();
                                }, 0);
                            }

                            setEdit((e: any) => ({ ...e, phone: !e.phone }));
                        }}
                    >
                        {edit.phone ? 'save' : 'edit'}
                    </Icon>
                </div>


                <div className="row">
                    Typ av konto:{' '}
                    {
                        {
                            private: 'Privat',
                            company: 'Företag',
                        }[user.account_type]
                    }
                </div>
            </div>
        </Style>
    );
}

type Props = {
    userID: string | null;
};

const Style = styled(Card)`

    .title-row,
    .name-row {
        display: flex;
        align-items: center;

        input {
            padding: 0;
            border-radius: 0;
            border: none;

            &:disabled {
                background: none;
                color: black;
            }
        }

        .title {
            font-size: 3rem;
        }

        .name {
            font-size: 1rem;
        }

        .icon {
            font-size: 1.25rem;
            margin-left: 1rem;
        }
    }

    .rows {
        border-top: 1px solid #eee;
        margin-top: 1rem;
        padding-top: 1rem;

        .row {
            color: #666;
            font-size: 0.85rem;
            margin-bottom: 0.5rem;

            a {
                color: inherit;
                margin-left: 0.3em;
            }
        }
    }

`