import React, { useState } from 'react'
import SellerSelect from 'src/components/sellers/SellerSelect';
import PNTrading from 'src/pn-trading-sdk/index';

export default function InventorySummarySelect() {

    const [loading, setLoading] = useState(false);
    const [seller, setSeller] = useState<null | number>(null);
    const [auctions, setAuctions] = useState<{
        id: number;
        name: string;
    }[]>([]);

    async function handleSellerChange(e: any) {
        const sellerID = e.value;
        setAuctions([]);

        if (sellerID === undefined) {
            return;
        }

        setSeller(sellerID);
        setLoading(true);

        try {

            const resp = await PNTrading.get(`/v2/summaries/inventory-printout/${sellerID}`);

            if (resp.success && resp.url) {
                return window.open(resp.url, '_blank');
            }

            if (resp.success === false && resp.code === 'multiple_auctions') {
                setAuctions(resp.auctions)
            }

        } catch (error: any) {
            console.error(error);
            alert('Något gick fel');
        } finally {
            setLoading(false);
        }

    }

    async function handleAuctionChange(e: any) {
        const sellerID = e.target.value;

        if (sellerID === 'default') {
            return;
        }

        setLoading(true);

        try {
            const resp = await PNTrading.get(`/v2/summaries/inventory-printout/${seller}?auction_id=${sellerID}`);

            console.log(resp);

            if (resp.success && resp.url) {
                return window.open(resp.url, '_blank');
            }

        } catch (error: any) {
            console.error(error);
            alert('Något gick fel');
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <SellerSelect disabled={loading} onChange={handleSellerChange} />

            {auctions.length > 0 && (
                <div>
                    <br/>
                    <p>Säljaren har flera auktioner, vänligen välj en för att öppna PDF.</p>
                    <label>Säljare</label><br/>
                    <select defaultValue="default" style={{width: '100%'}} onChange={handleAuctionChange} disabled={loading}>
                        <option disabled value="default">Välj auktion</option>
                        {auctions.map((auction) => (
                            <option key={auction.id} value={auction.id}>({auction.id}) {auction.name}</option>
                        ))}
                    </select>
                </div>
            )}
        </>
    )
}