import React, { useEffect, useState } from 'react';
import Button from '../elements/Button';
import Page from '../layout/Page';
import Card from '../layout/Card';
import Icon from '../elements/Icon';
import usePopup from '../utils/hooks/usePopup';
import UploadObjects from '../layout/popups/UploadObjects';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Auctions from '../utils/api/Auctions';
import MediaLibrary from '../layout/popups/MediaLibrary';
import EditableField from '../elements/EditableField';
import Images from '../utils/api/Images';
import RemoveObjectFromAuction from '../layout/popups/RemoveObjectFromAuction';
import CopyAuction from '../layout/popups/CopyAuction';
import PNTrading from 'src/pn-trading-sdk';
import { dateToReadableDate } from '../utils/Util';
import ObjectsTable from '../components/auction/ObjectsTable';
import SellerSelect from '../components/sellers/SellerSelect';
import CategorySelect from '../components/CategorySelect';

const lockedMeta = ['Adress', 'Visning', 'Avslut', 'Utlämning'];

export default function PageAuction(props) {
	const limit = 10;

	let id = props.match.params.id;

	const history = useHistory();
	const [open] = usePopup();

	// Edit variables.
	const [temp, setTemp] = useState();
	const [edit, setEdit] = useState(false);

	const [metadata, setMetadata] = useState();

	// Store the auction object.
	const [auction, setAuction] = useState();
	const [thumbnail, setThumbnail] = useState();
	const [objects, setObjects] = useState();

	const [auctionStats, setAuctionStats] = useState(undefined);

	// Load auction object when id changes.
	useEffect(() => {
		(async () => {
			let auction = await Auctions.get(id);

			setAuctionStats(await PNTrading.get(`/admin/stats/auction/${id}/summary`));
			if (auction.image) setThumbnail(await Images.get(auction.image));
			setAuction(auction);
			setMetadata(auction?.data?.meta ?? []);

			await loadObjects({ page: 0 });
		})();
	}, [id]);

	useEffect(() => {
		console.log('change');
		let a = setTimeout(async () => {
			console.log(metadata, auction?.data?.meta);
			if (metadata == auction?.data?.meta) return;

			let data = JSON.stringify({ ...auction.data, meta: metadata });
			await Auctions.update(id, { data });
		}, 1000);
		return () => clearTimeout(a);
	}, [id, metadata, auction]);

	// Opens a MediaLibrary and updates image on save.
	const media = (id) => {
		open(
			<MediaLibrary
				defaultImage={thumbnail?.id}
				onChange={async (image) => {
					setThumbnail(image);
					await Auctions.update(id, { image: image.id });
					setAuction((auction) => ({ ...auction, image: image.id }));
				}}
			/>
		);
	};

	// Opens the upload popup.
	const uploadObjects = (id) => {
		open(
			<UploadObjects
				auctionID={id}
				onComplete={async () => {
					await loadObjects({ page: 0 });
				}}
			/>
		);
	};

	// Initiates auction duplication.
	const copyAuction = (id) => {
		open(<CopyAuction auctionID={id} auction={auction} objects={objects} />);
	};

	const save = async (id, _temp = temp) => {
		setEdit(false);
		if (_temp == undefined) return;

		// Auto update metadata on date change.
		if (_temp.ending) {
			setMetadata((metadata) => {
				let readableDate = dateToReadableDate(new Date(_temp.ending));
				let index = metadata.findIndex((m) => m.key == 'Avslut');

				if (index == -1) {
					metadata.push({
						key: 'Avslut',
						value: readableDate,
					});
				} else {
					metadata[index] = {
						key: 'Avslut',
						value: readableDate,
					};
				}

				let data = JSON.stringify({ ...auction.data, meta: metadata });
				Auctions.update(id, { data });

				return metadata;
			});
		}

		// Update auction.
		await Auctions.update(id, _temp);
		setAuction((auction) => ({ ...auction, ..._temp }));
	};

	const updateCategory = async (e) => {
		await PNTrading.put(`/auctions/${id}/update-categories/${e.target.value}`);
	};

	// Create a function to update the key type in the auction. Use the function Auctions.update to update the auction.
	const updateType = async (type) => {
		await Auctions.update(id, {
			type: type == 'none' ? null : type,
		});
	};

	const updateSeller = async (e) => {
		await PNTrading.put(`/auctions/${id}/update-seller/${e.value}`);
	};

	const updateTax = async (e) => {
		await PNTrading.put(`/auctions/${id}/update-tax/${e.target.value}`);
	};

	const updateShipping = async (e) => {
		await PNTrading.put(`/auctions/${id}/update-shippable/${e.target.value}`);
	};

	const loadObjects = async ({ sorting, filters, page }) => {
		let params = { page, limit };
		let objects = await PNTrading.get(
			`/auctions/${id}/objects?${Object.keys(params)
				.map((key) => `${key}=${params[key]}`)
				.join('&')}&noCache=true`
		);
		setObjects(objects);
		return objects.data;
	};

	function handleObjectPositionOnChange(row) {
		let timeoutID = null;
		return async (e) => {

			if (timeoutID) clearTimeout(timeoutID);
			timeoutID = setTimeout(async () => {
				
				const position = Number(e.target.value);

				if (isNaN(position)) {
					alert('Position måste vara ett nummer.');
					return;
				}

				if (position < 1) {
					alert('Position måste vara större än 0.');
					return;
				}

				const resp = await PNTrading.put(`/objects/${row.id}`, {
					position,
				})

				if (resp.id !== row.id) {
					alert('Något gick fel.');
					return;
				}
				
			}, 500);
		};
	}

	if (!auction || !objects)
		return (
			<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
		);

	return (
		<Style>
			<Card size="small" className={`stats-card`}>
				<h3>Bud Totalt värde</h3>
				<h1>{auctionStats?.leading_bids_sum} kr</h1>
			</Card>

			<Card size="small" className={`stats-card`}>
				<h3>Bud Potentiellt värde</h3>
				<h1>{auctionStats?.potential_value} kr</h1>
			</Card>

			<Card size="small" className={`stats-card`}>
				<h3>Antal bud</h3>
				<h1>{auctionStats?.num_bids_placed} st</h1>
			</Card>

			<Card size="small" className={`stats-card`}>
				<h3>Antal maxbud</h3>
				<h1>{auctionStats?.num_max_bids_placed} st</h1>
			</Card>

			{/* Header / Info card. */}
			<Card size="medium" className={`info-card`}>
				<div className="image" onClick={() => media(id)}>
					<img src={thumbnail ? thumbnail.sizes['500x500'] : 'https://via.placeholder.com/500'} />
					<Icon>insert_photo</Icon>
				</div>

				<div className="info">
					<EditableField className="title" edit={edit} data={auction} field={'name'} temp={temp} setTemp={setTemp} />

					<div className="spacer s"></div>

					<EditableField edit={edit} data={auction} type="textarea" field={'description'} temp={temp} setTemp={setTemp} />

					<div className="spacer s"></div>

					<input 
						disabled={!edit} 
						type="datetime-local" 
						onChange={(e) => setTemp((temp) => ({ ...temp, ending: e.target.value }))} 
						value={temp?.ending} 
						defaultValue={auction?.ending ? new Date(auction?.ending).toLocaleTimeString('sv-SE', { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }) : undefined} 
					/>

					<div className="spacer s"></div>

					<div className="row">
						{!edit ? <Button onClick={() => setEdit(true)}>Redigera</Button> : <Button onClick={() => save(id)}>Spara</Button>}

						<div className="spacer ws"></div>

						<Button onClick={() => uploadObjects(id)}>Importera objekt</Button>

						<div className="spacer ws"></div>

						<Button onClick={() => copyAuction(id)}>Kopiera auktion</Button>
					</div>
				</div>
			</Card>

			{/* Meta card. */}
			<Card size="medium" className={`meta-card`}>
				<h2>Auktionsinfo</h2>
				<label>Ändringarna sparas automatiskt.</label>
				<div className="spacer s"></div>

				<div className="row">
					<div className="column">
						<label>Status</label>
						<select
							value={temp?.status ?? auction?.status}
							disabled={auction?.status == 'ended' || auction?.status == 'empty'}
							onChange={(e) => {
								let status = e.target.value;

								if (status == 'ended') {
									let t = window.confirm('OBS! Denna ändring är permanent! För att dölja auktionen, välj istället "Utkast"! Om du ändå vill avsluta auktionen klicka på OK nedan.');
									if (!t) {
										e.preventDefault();
										return;
									}
								}

								setTemp((t) => {
									let newTemp = { ...(t ?? {}), status };
									save(id, newTemp);
									return newTemp;
								});
							}}
						>
							<option value="active">Publicerad</option>
							<option value="draft">Utkast</option>
							<option disabled value="ended">
								Avslutad
							</option>
							<option disabled value="empty">
								Inga aktiva objekt
							</option>
						</select>
					</div>

					<div className="spacer ws"></div>

					{objects && (
						<div className="column">
							<label>Sätt en kategori för alla objekt</label>

							<CategorySelect defaultValue={objects?.data?.reduce((prev, o) => (o.category == prev ? o.category : undefined), objects?.data?.[0]?.category)} onChange={updateCategory} />
						</div>
					)}
				</div>

				<div className="spacer s"></div>

				<div className="column">
					<label>Typ av auktion</label>
					<br />
					<select onChange={(e) => updateType(e.target.value)} defaultValue={auction?.type}>
						<option value="none">Välj typ...</option>

						<option value="bankruptcy">Konkurs</option>
						<option value="divestment">Avyttring</option>
						<option value="settlement">Avveckling</option>
						<option value="submission">Inlämning</option>
						<option value="private">Privatperson</option>
						<option value="vehicle">Fordon & Entreprenad</option>
					</select>
				</div>

				<div className="spacer s"></div>

				<div className="column">
					<label>Säljare</label>
					<SellerSelect onChange={updateSeller} style={{ minWidth: '15rem' }} defaultValueID={objects?.data?.reduce((prev, o) => (o.seller == prev ? o.seller : undefined), objects?.data?.[0]?.seller)} />
				</div>

				<div className="spacer s"></div>

				{/* Tax. */}
				{objects && (
					<div className="column">
						<label>Momsfri</label>
						<div className="input">
							<select defaultValue={objects?.data?.reduce((prev, o) => (o.no_tax == prev ? o.no_tax : undefined), objects?.data?.[0]?.no_tax)} onChange={updateTax}>
								<option value="NO">Nej</option>
								<option value="YES">Ja</option>
							</select>
						</div>
					</div>
				)}

				<div className="spacer s"></div>

				{/* Shipping. */}
				{objects && (
					<div className="column">
						<label>Frakt</label>
						<div className="input">
							<select defaultValue={objects?.data?.reduce((prev, o) => (o.shippable == prev ? o.shippable : undefined), objects?.data?.[0]?.shippable)} onChange={updateShipping}>
								<option value="NO">Nej</option>
								<option value="YES">Ja</option>
							</select>
						</div>
					</div>
				)}

				<div className="spacer m"></div>
				<table>
					<thead>
						<tr>
							<th>Rubrik</th>
							<th>Värde</th>
						</tr>
					</thead>
					<tbody>
						{metadata?.map((meta) => (
							<tr>
								<td>
									<input type="text" value={meta.key} onChange={(e) => setMetadata(metadata.map((m) => (m == meta ? { ...meta, key: e.target.value } : m)))} disabled={lockedMeta.includes(meta.key)} />
								</td>
								<td>
									<input type="text" value={meta.value} onChange={(e) => setMetadata(metadata.map((m) => (m == meta ? { ...meta, value: e.target.value } : m)))} />
								</td>
								<td>
									{!lockedMeta.includes(meta.key) && (
										<Icon
											onClick={() => {
												setMetadata(metadata.filter((m) => m != meta));
											}}
										>
											delete
										</Icon>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className="spacer s"></div>
				<div
					className="button secondary"
					onClick={() => {
						setMetadata([...metadata, { key: '', value: '' }]);
					}}
				>
					Lägg till ny rad
				</div>
			</Card>

			<Card size="full">
				<h2>Objekt i auktionen</h2>
				<div className="spacer m"></div>

				<ObjectsTable
					auction={id}
					structure={[
						{
							heading: 'Position / ID',
							key: 'id',
							component: (row) => (
								<div>
									<input defaultValue={row.position} style={{width: 50}} onChange={handleObjectPositionOnChange(row)} /> / <span>{row.id}</span>
								</div>
							),
						},
						{
							heading: 'Modell',
							key: 'title',
							// sortable: true,
							component: (row) => {
								let text = row.title;
								return <>{text.length > 50 ? text.substring(0, 50) + '...' : text}</>;
							},
						},
						{
							heading: 'Status',
							// sortable: true,
							component: (row) =>
								({
									available: 'Tillgänglig',
									ended: 'Avslutad',
								}[row.status]),
						},
						{
							heading: '',
							className: 'row-icons',
							component: (row) => {
								return (
									<div>
										<Icon onClick={() => history.push(`/objects/${row.id}`)}>edit</Icon>
										<Icon onClick={() => open(<RemoveObjectFromAuction setObjects={setObjects} auction={id} id={row.id} />)}>close</Icon>
									</div>
								);
							},
						},
					]}
				/>

				<div className="spacer m"></div>
			</Card>
		</Style>
	);
}

const Style = styled(Page)`
	.row-icons {
		div {
			display: flex;
			justify-content: flex-end;
			font-size: 1.5em;

			.icon {
				margin-left: 0.5em;
			}
		}
	}

	.stats-card {
		h1 {
			margin-top: 0.5rem;
			font-size: 2.5rem;
		}
	}

	.meta-card {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		table {
			th {
				text-align: left;
			}

			tbody {
				tr {
					td {
						.icon {
							font-size: 1.5em;
						}
					}
				}
			}
		}

		.mobile & {
			& > .row {
				width: 100%;
				flex-direction: column;

				& > * {
					min-width: 0;
					margin-bottom: 0.25rem;
				}
			}

			table {
				width: 100%;
			}
		}
	}

	.info-card {
		display: flex;

		.image {
			width: 15em;
			position: relative;
			cursor: pointer;
			flex-shrink: 0;

			.icon {
				position: absolute;
				z-index: 1;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 4em;
				color: white;
				opacity: 0;
				transition: opacity 300ms;
			}

			img {
				border-radius: 0.5rem;
				width: 100%;
				transition: filter 300ms;
				// line-height: 0 !important;
			}

			&:hover {
				.icon {
					opacity: 1;
				}

				img {
					filter: brightness(0.5);
				}
			}
		}

		.info {
			display: flex;
			flex-direction: column;
			margin-left: 2em;
			width: 100%;

			.title {
				font-size: 2em;
			}

			.button {
				align-self: flex-start;
			}
		}

		.mobile & {
			align-items: center;
			flex-wrap: wrap;

			.image {
				width: 100%;
			}
			.info {
				margin-top: 1rem;
				margin-left: 0;

				.row {
					flex-direction: column;

					& > * {
						margin-bottom: 0.25rem;
					}
				}
			}
		}
	}


	@media (min-width: 1000px) {
		.card.medium {
			grid-column: 1/-1;
		}
	}
`;
